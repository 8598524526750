@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: 0;
  scroll-behavior: smooth;
}

:root {
  --color1: #7e63d3;
  --color2: #b2a2e5;
  --colorrgba: rgba(90, 57, 198);
}

.text-purple {
  color: #6545ca;
}

/* Custom Scroll-- */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6545ca;
}

/* Custom Scroll-- */

/* Scroll-to-top Butoon - Only for Demo */
#top {
  cursor: pointer;
  background-color: #6545ca;
  position: fixed;
  bottom: 2rem;
  right: 0rem;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#top i {
  color: #fff;
  font-size: 1.3rem;
}

#whatsapp {
  background: #25d366;
  position: fixed;
  bottom: 50%;
  transform: translateY(50%);
  right: 0rem;
  height: 120px;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  border-radius: 3px;
}

#whatsapp a {
  transform: rotate(-90deg);
  /* background-color: #3c9f5b; */
  color: #fff;
  border-radius: 50%;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 100% !important;
  justify-content: center;
  margin: 0;
  padding: 1.2rem;
  width: 30px !important;
}

#whatsapp i {
  fill: #fff;
  height: auto;
  width: auto;
  font-size: 2rem;
}

/* Whatsapp=== */
.shub-btn {
  color: #fff;
  background-color: #6545ca;
  border-color: #6545ca;
}

.shub-btn:hover {
  color: #6545ca;
  background-color: #fff;
  border-color: #fff;
}

.mybtn {
  background-color: #7e63d3;
  color: #fff;
}

.mybtn:hover {
  color: #6545ca;
  background-color: #fff;
  border-color: #fff;
}

.icons img {
  transition: all 0.5s ease;
}

.icons img:hover {
  transform: translateY(-5px);
  transition: all 0.5s ease;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo img:hover {
  transform: rotate(360deg);
  transition: all 0.4s ease;
}

.logo img {
  transition: all 0.4s ease;
}

#header {
  transition: all 0.5s;
  z-index: 997;
  /* padding: 15px 0; */
}

#header.header-scrolled,
#header.header-inner-pages {
  background: rgba(0, 0, 0, 0.9);
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  font-family: "Courgette", cursive;
}

#header .logo span {
  background: var(--color1);
  padding: 1px 9px;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 70px;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
  padding: 0;
}
.navbar ul li {
  padding: 10px;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 0 10px 30px; */
  font-size: 15px;
  border-radius: 20px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #fff;
  background: var(--color1);
}

.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  border: 2px solid var(--color1);
  font-weight: 600;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: var(--color1);
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #0c3c53;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: var(--color1);
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: var(--color1);
  background: #fff;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
  color: rgba(0, 0, 0, 0.8);
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: var(--color1);
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.bannerimg {
  transform: translateY(25px);
  width: 100%;
}

.bannerimg img {
  transform: translate(60px, 40px);
  width: 125%;
  animation: bump 1s alternate infinite;
}

@keyframes bump {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(10px);
  }
}

#hero {
  width: 100%;
  height: 90vh;
  background: var(--colorrgba);
  border-radius: 100% 0% 100% 0% / 0% 60% 40% 100%;
  position: relative;
  /* background-image: url(/src/Component/images/bannertech.png); */
  /* background-size: cover; */
}

#hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(193, 180, 232, 0.1);
}

#hero .container {
  padding-top: 72px;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 50px;
  font-size: 24px;
}

/* #hero .btn-get-started {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #fff;
  background: var(--color1);
  
  
} */
#hero .btn-get-started i {
  animation: bump2 0.6s alternate infinite;
}

@keyframes bump2 {
  0% {
    transform: translateY(-10px);
  }

  50% {
    /* transform: translateY(10px); */
  }

  100% {
    transform: translateY(10px);
  }
}

#hero .btn-get-started {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 24px 13px 24px 13px;
  border-radius: 18px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #fff;
  background: var(--color1);
}

/* animation: bump 1s alternate infinite; */

#hero .btn-get-started:hover {
  background: var(--color1);
}

#hero .btn-watch-video {
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  margin: 10px 0 0 25px;
  color: #fff;
  line-height: 1;
}

#hero .btn-watch-video i {
  line-height: 0;
  color: #fff;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}

#hero .btn-watch-video:hover i {
  color: var(--color1);
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 991px) {
  #hero {
    height: 100vh;
    text-align: center;
  }

  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 100%;
  }

  #hero {
    border-radius: 0% 0% 50% 50% / 10% 10% 10% 10%;
  }

  #hero .btn-get-started {
    font-size: 16px;
    /* padding: 10px 24px 11px 24px; */
    padding: 23px 12px 11px 12px;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  /* overflow: hidden; */
}

.section-bg {
  background-color: #f3f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: var(--color1);
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: var(--color1);
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Whu Us
--------------------------------------------------------------*/
.why-us {
  background-color: rgba(7, 54, 106, 0.1);
}

.why-us .box {
  padding: 30px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  /* transition: all ease-in-out 0.3s; */
  height: 100%;
  background-color: #fff;
  transition: all 1s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.why-us .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: var(--color1);
}

.why-us .box h4 {
  font-size: 23px;
  font-weight: 600;
  padding: 0;
  margin: 12px 0;
  color: #6c665c;
}

.why-us .box p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.why-us .box:hover p {
  color: #fff;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.why-us .box:hover a {
  color: var(--colorrgba);
}

.why-us .box:hover {
  /* background-image:linear-gradient(rgba(90,57,198,0.4),rgba(90,57,198,0.4)), url('../images/bg-box.jpg'); */
  padding: 30px 30px 30px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
  transition: all 1s ease;
}

.why-us .box.hoverbox1 {
  transition: all 1s ease;
}

.why-us .box.hoverbox1:hover {
  background-image: linear-gradient(
      rgba(90, 57, 198, 0.8),
      rgba(90, 57, 198, 0.8)
    ),
    url("../images/smm.png");
  background-size: cover;
  background-position: center;
  transition: all 1s ease;
}

.why-us .box.hoverbox2 {
  transition: all 1s ease;
}

.why-us .box.hoverbox2:hover {
  background-image: linear-gradient(
      rgba(90, 57, 198, 0.8),
      rgba(90, 57, 198, 0.8)
    ),
    url("../images/webdesign.png");
  background-size: cover;
  background-position: center;
  transition: all 1s ease;
}

.why-us .box.hoverbox3 {
  transition: all 1s ease;
}

.why-us .box.hoverbox3:hover {
  background-image: linear-gradient(
      rgba(90, 57, 198, 0.8),
      rgba(90, 57, 198, 0.8)
    ),
    url("../images/webdevelopment.jpg");
  background-size: cover;
  background-position: center;
  transition: all 1s ease;
}

.why-us .box.hoverbox4 {
  transition: all 1s ease;
}

.why-us .box.hoverbox4:hover {
  background-image: linear-gradient(
      rgba(90, 57, 198, 0.8),
      rgba(90, 57, 198, 0.8)
    ),
    url("../images/logodesign.png");
  background-size: cover;
  background-position: center;
  transition: all 1s ease;
}

.why-us .box.hoverbox5 {
  transition: all 1s ease;
}

.why-us .box.hoverbox5:hover {
  background-image: linear-gradient(
      rgba(90, 57, 198, 0.8),
      rgba(90, 57, 198, 0.8)
    ),
    url("../images/android-app-development.png");
  background-size: cover;
  background-position: center;
  transition: all 1s ease;
}

.why-us .box.hoverbox6 {
  transition: all 1s ease;
}

.why-us .box.hoverbox6:hover {
  background-image: linear-gradient(
      rgba(90, 57, 198, 0.8),
      rgba(90, 57, 198, 0.8)
    ),
    url("../images/emailers.jpg");
  background-size: cover;
  background-position: center;
  transition: all 1s ease;
}

.why-us .box:hover .btn {
  background: #fff !important;
  color: var(--colorrgba) !important;
}

.why-us .box:hover span,
.why-us .box:hover h4 {
  color: #fff;
}

/* .box a{
  background-color: rgb(33, 36, 133);

} */

#right {
  position: relative;
  z-index: auto;
  background-color: #fff;
}

#right::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -30px;
  height: 75%;
  transform: translateY(-50%);
  width: 100%;
  border-radius: 50px 50px;
  background-color: rgba(126, 99, 211, 0.4);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: -1;
}

#right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -30px;
  height: 75%;
  transform: translateY(-50%);
  width: 100%;
  border-radius: 50px 50px;
  background-color: rgba(126, 99, 211, 0.4);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: -1;
}

.vision_mission {
  background-image: url("../images/bg.png");
  /* background-color: rgba(193, 180, 232, 0.1); */
  background-size: cover;
  background-image: linear-gradient(
      rgba(193, 180, 232, 0.2),
      rgba(193, 180, 232, 0.2)
    ),
    url("../images/3302887.jpg");
  background-repeat: cover;
}

.we_are {
  /* background-image:linear-gradient(rgba(255,255,255,0.2),rgba(255,255,255,0.2)), url('../images/bg-lines-service-slider2.png'); */
  /* background-size: cover; */
  /* background-image: linear-gradient(to left, #6545ca, #6b4dcc, #7254cf, #785cd1, #7e63d3); */
  background: #6545ca;
}

/* Work For */
.workfor {
  /* background-image:linear-gradient(rgba(193, 180, 232, 0.2),rgba(193, 180, 232, 0.2)), url('../images/3302887.jpg'); */
  background-color: rgba(193, 180, 232, 0.1);
  background-size: cover;
}

/* tech stack--- */
.tech_stack {
  background-color: rgba(193, 180, 232, 0.1);
}

.tech_stack .container {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  background-color: #fff;
}

/* Contact us start */

.contact-us {
  background-color: rgba(90, 57, 198, 0.9);
  /* background-color:#000; */
  border-radius: 20px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  background-image: url("../images/contact-form-bg.webp");
  background-size: cover;
  background-position: center;
  position: relative;
}

.contact-us ::before {
  content: "";
  position: absolute;
  top: -14px;
  left: 50%;
  height: 5%;
  width: 5%;
  background-color: #fff;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  transform: translate(-50%);
}

.contact-us ::after {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 50%;
  height: 5%;
  width: 5%;
  background-color: #fff;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  transform: translate(-50%);
}

/* Contact us end */

/* Contact page--- */

#contact {
  margin-top: 70px;
  background-color: var(--color1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#contact::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: #fff;
  background-image: url("../images/circle-decor.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

.contact-box {
  width: clamp(100px, 70%, 1000px);
  /* margin: 80px 50px; */
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  /* position: relative; */
  z-index: 1;
}

.contact-links,
.contact-form-wrapper {
  width: 50%;
  padding: 8% 5% 10% 5%;
}

.contact-links {
  background-color: #1f2e43;
  background: radial-gradient(
      circle at 55% 92%,
      #426691 0 12%,
      transparent 12.2%
    ),
    radial-gradient(circle at 94% 72%, #426691 0 10%, transparent 10.2%),
    radial-gradient(
      circle at 20% max(78%, 350px),
      #263a53 0 7%,
      transparent 7.2%
    ),
    radial-gradient(circle at 0% 0%, #263a53 0 40%, transparent 40.2%), #1f2e43;
  border-radius: 10px 0 0 10px;
}

.contact-form-wrapper {
  background-color: var(--color1);
  border-radius: 0 10px 10px 0;
}

@media (max-width: 575px) {
  .contact-us ::after {
    height: 3%;
    width: 13%;
  }
  .contact-us ::before {
    height: 3%;
    width: 13%;
  }
}
@media only screen and (max-width: 800px) {
  .contact-links,
  .contact-form-wrapper {
    width: 100%;
  }

  .contact-links {
    border-radius: 10px 10px 0 0;
  }

  .contact-form-wrapper {
    border-radius: 0 0 10px 10px;
  }

  #contact::before {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .contact-box {
    width: 95%;
    margin: 8% 5%;
  }

  #contact::before {
    display: none;
  }
}

#contact h2 {
  font-family: "Arimo", sans-serif;
  color: #fff;
  font-size: clamp(30px, 6vw, 60px);
  letter-spacing: 2px;
  text-align: center;
  transform: scale(0.95, 1);
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 50px;
}

.link {
  margin: 10px;
  cursor: pointer;
}

#contact img {
  width: 45px;
  height: 45px;
  filter: hue-rotate(220deg) drop-shadow(2px 4px 4px #0006);
  transition: 0.2s;
  user-select: none;
}

#contact img:hover {
  transform: scale(1.1, 1.1);
}

#contact img:active {
  transform: scale(1.1, 1.1);
  filter: hue-rotate(220deg) drop-shadow(2px 4px 4px #222) sepia(0.3);
}

.form-item {
  position: relative;
}

label,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

label {
  position: absolute;
  top: 10px;
  left: 2%;
  color: #999;
  font-size: clamp(14px, 1.5vw, 18px);
  pointer-events: none;
  user-select: none;
}

#contact input,
textarea {
  width: 100%;
  outline: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  background-color: transparent;
  margin-bottom: 20px;
  padding: 12px;
  font-size: 1rem;
}

.form-control::placeholder {
  color: #fff;
  font-size: 1rem;
}

.form-control:focus {
  color: #fff;
  background-color: transparent;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  color: #fff;
  border: none;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input:focus + label,
input:valid + label,
textarea:focus + label,
textarea:valid + label {
  font-size: clamp(13px, 1.3vw, 16px);
  color: #777;
  top: -20px;
  transition: all 0.225s ease;
}

.submit-btn {
  background-color: #fd917e;
  filter: drop-shadow(2px 2px 3px #0003);
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: clamp(16px, 1.6vw, 18px);
  display: block;
  padding: 12px 20px;
  margin: 2px auto;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
}

.submit-btn:hover {
  transform: scale(1.1, 1.1);
}

.submit-btn:active {
  transform: scale(1.1, 1.1);
  filter: sepia(0.5);
}

@media only screen and (max-width: 800px) {
  #contact h2 {
    font-size: clamp(40px, 10vw, 60px);
  }
}

@media only screen and (max-width: 400px) {
  #contact h2 {
    font-size: clamp(30px, 12vw, 60px);
  }

  #contact .links {
    padding-top: 30px;
  }

  #contact img {
    width: 38px;
    height: 38px;
  }
}

/* Contact page--- */

/* Footer--- */
/* Footer */

.footer {
  /* background-image:url('../images/footerbg2.png'); */
  /* background-image:linear-gradient(rgba(126,99,211,0.4),rgba(126,99,211,0.4)),url('../images/light-bg.jpg'); */
  background-position: center;
  background-size: cover;
  /* background-color: #7e63d3; */
  background-color: #fff;
  font-size: 1rem;
  color: #ffffff;
  padding: 23px 0;
}

.footer .form-control::placeholder {
  color: gray;
}

.footer p {
  color: #000;
}

.rounded-circle-start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-circle-end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Footer--- */

/* Call to Action */

.cta {
  background: #6545ca;
}

.cta1 a {
  background-color: var(--color2);
  color: #fff;
}

/* .cta .cta1 {
  background-image: url(https://kaleidoscope.app/images3/animation/content_anywhere.svg);
  background-color: #6545ca;
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: center;



} */

/* Call to Action */

/* Sidebox */

.sidebox {
  transform: translateY(-123px);
}

.spacer {
  background-color: rgba(193, 180, 232, 0.1);
}

.newform {
  background-color: rgba(193, 180, 232, 0.1);
}

.sidebox {
  position: relative;
}

.sidebox::after {
  content: "";
  position: absolute;
  top: -22px;
  right: 20px;
  width: 70px;
  height: 46px;
  background: url(https://webstatic.chargebee.com/assets/web/531/images/footer/cb-quote-white.svg)
    no-repeat;
  background-size: contain;
}

.formbox h2 {
  font-size: 3rem;
}

@media (max-width: 575px) {
  .formbox h2 {
    font-size: 1.5rem;
  }

  .sidebox {
    transform: translateY(0px);
  }
}

/* Sidebox */

/* About--- */
.aboutmain {
  background-color: #6545ca;
}

.about {
  height: 100vh;
  /* background-image:linear-gradient(rgba(101,69,202,0.3),rgba(101,69,202,0.3)),url('../images/aboutbanner1.jpg'); */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/aboutbanner1.webp");
  background-size: cover;
  border-radius: 0% 0% 50% 50% / 10% 10% 10% 10%;
}

/* who we are start */
.who_we_are h6 {
  color: #6545ca;
}

/* medicine start */
.pa-medicine-box {
  text-align: center;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 23px;
  transition: 0.3s;
  border-bottom: 3px solid transparent;
}

.pa-medi-icon i {
  font-size: 40px;
  /* height: 50px; */
  color: #7e63d3;
}

.pa-medi-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: auto;
  line-height: 100px;
  border-radius: 100%;
  transform: translateY(-60px);
  background: #fff;
  border: 1px solid #6545ca;
  box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 5%);
}

.pa-medicine-box h2 {
  font-size: 22px;
  /* margin: 20px 0 10px; */
  text-transform: capitalize;
}

.pa-medicine-box a {
  color: #000;
  text-decoration: none;
}

.pa-medicine-box p {
  margin: 0px;
  padding-top: 10px;
}

.pa-medicine-box:hover {
  transform: translateY(-5px);
  border-color: #7e63d3;
}

.pa-medicine-box:hover .pa-medi-icon i {
  color: #fff;
}

.pa-medicine-box:hover .pa-medi-icon {
  background: #7e63d3;
  transition: all 0.3s ease;
}

/* who we are end */

/* Experience-- */
/* testimonial start */
.pa-tesimonial-box {
  display: flex;
  max-width: 900px;
  justify-content: center;
  margin: auto;
  padding: 35px;
  align-items: center;
  position: relative;
}

.pa-tesimonial {
  position: relative;
}

.testi-img {
  position: absolute;
  left: 0;
  top: -49px;
  height: auto;
  width: 16%;
}

.prod-img {
  position: absolute;
  right: 0;
  top: -49px;
  height: auto;
  width: 16%;
}

.pa-heading h5 {
  margin-bottom: 0;
  text-transform: capitalize;
  font-size: 20px;
  color: #6545ca;
}

.pas-testi-img {
  width: 263px;
  z-index: 1;
  position: relative;
}

.pa-tesimonial-box:after {
  position: absolute;
  content: "";
  left: -8px;
  top: -2px;
  background-image: radial-gradient(#6545ca 2px, transparent 2px);
  background-size: 20px 20px;
  width: 200px;
  bottom: -8px;
  z-index: 0;
  /* color: #6545ca; */
}

.pas-testi-img:after {
  position: absolute;
  content: "";
  right: -90px;
  top: -35px;
  width: 150px;
  height: 150px;
  background: rgba(126, 99, 211, 0.12);
  z-index: -1;
  margin: auto;
  border-radius: 100%;
  animation: move 1s alternate infinite;
  transition: all 1s ease;
}

@keyframes move {
  0% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-5px);
  }
}

@media (max-width: 575px) {
  .pas-testi-img:after {
    position: absolute;
    content: "";
    right: -45px;
    top: -35px;
    width: 100px;
    height: 100px;
  }
  .about {
    background-position: center;
  }
}

/* printed cta strat-- */
.printed_cta {
  background-color: #6545ca;
  /* height: 70vh; */
}

.printed_box2 {
  transform: translateY(55px);
}

/* printed cta strat-- */

.cta1 {
  position: relative;
}

.ctaabout {
  position: relative;
  overflow: hidden;
}

.ctaabout::before {
  background-image: url("../images/breadcrumb2.webp");
  background-color: #6545ca;
  animation: run 10s linear infinite;
  width: 200%;
  position: absolute;
  background-size: 50% auto;
  bottom: 0;
  content: "";
  left: 0;
  top: 0;
}

@keyframes run {
  to {
    transform: translateX(-50%);
  }
}

/* Counter-- */
/* counter start */
.pa-counter-box h2 {
  font-size: 35px;
  margin-bottom: 15px;
  display: inline-block;
}

.pa-counter-box span {
  font-size: 25px;
  font-weight: 600;
}

.pa-counter-box {
  text-align: center;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 30px 30px 23px;
  transition: 0.3s;
}

.pa-counter-box p {
  margin-bottom: 0;
  font-weight: 400;
  text-transform: capitalize;
}

.spacer-bottom {
  padding-bottom: 30px;
}

.spacer-top {
  padding-top: 30px;
}

.pa-counter {
  margin-bottom: 260px;
  background-color: #6545ca;
}

.pa-counter-main {
  margin-bottom: -180px;
}

.pa-heading {
  text-align: center;
  margin-bottom: 25px;
}

.pa-counter-box svg {
  width: 50px;
  height: auto;
  fill: #6545ca;
  display: block;
  text-align: center;
  margin: 0 auto 10px;
}

@media (max-width: 575px) {
  .pa-counter-box h2 {
    font-size: 25px;
  }

  .pa-counter-box {
    margin-bottom: 30px;
    padding: 30px 30px 26px;
    height: 212px;
    width: 162px;
  }
  .pa-counter {
    margin-bottom: 0;
    background-color: #6545ca;
  }
  .pa-counter-main {
    margin-bottom: 0;
  }
  .printed_box2 {
    transform: translateY(0px);
  }
}

/* counter end */
/* Counter-- */

/* About--- */

/* Services start-- */
.services {
  margin-top: 70px;
  height: 90vh;
  background-position: center;
  /* background-image:linear-gradient(rgba(101,69,202,0.3),rgba(101,69,202,0.3)),url('../images/aboutbanner1.jpg'); */
  background-image: linear-gradient(
      rgba(101, 69, 202, 0.1),
      rgba(101, 69, 202, 0.1)
    ),
    url("../images/service1-min.jpg");
  background-size: cover;
  border-radius: 0% 0% 50% 50% / 10% 10% 10% 10%;
}

.content-box h2 {
  color: #fff;
  font-size: 2rem;
  border-bottom: 1px solid #fff;
}

.content-box p {
  color: #fff;
}

.row1 {
  background-image: linear-gradient(
      rgba(101, 69, 202, 0.8),
      rgba(101, 69, 202, 0.8)
    ),
    url("../images/173735267378254.jpg");
  background-color: #6545ca;
  border-radius: 0 250px 250px 0;
  background-size: cover;
  position: relative;
}

/* .row1::before{
  content:'';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url('../images/circle-decor-removebg-preview.png');
  background-size: cover;
} */
.row2 {
  background-color: #6545ca;
  border-radius: 250px 0 0 250px;
}

.rotate {
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Services end-- */

/* Projects-- */
.scrollbox {
  height: 100vh;
  overflow: hidden;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);
}

.scrollbox:hover::before {
  background-color: transparent;
}

.scrollbox img {
  transition: all 4s ease;
  position: relative;
  top: 0;
}

.scrollbox:hover .scrollbox_body {
  top: 0;
  left: -500px;
  transition: all 0.4s ease;
}

.scrollbox_body {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
}

.scrollbox:hover img {
  /* transform: scale(1.2); */
  top: -600px;
  transition: all 4s ease;
}

@media (max-width: 575px) {
  .services {
    background-position: initial;
  }
  .row1 {
    border-radius: 0 0px 250px 0;
  }
  .row1 img {
    width: 90%;
  }
  .row2 {
    border-radius: 250px 0 0 0px;
  }
  .row2 img {
    width: 90%;
  }
}

/* Projects-- */

/* Landing-page---- */
@media (max-width: 575px) {
  .carousel-inner {
    width: 100% !important;
  }
  .carousel-control-prev {
    left: 4% !important;
  }
  .carousel-control-next {
    right: -10% !important;
  }
}
.carousel-inner {
  position: relative;
  width: 80%;
  overflow: hidden;
  margin: auto;
}
.carousel-control-prev {
  height: 50px;
  background: red;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 11%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  padding: 0;
  color: #fff;
  text-align: center;
  background: #fff;
  border: 0;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.15s ease;
}
.carousel-control-next {
  height: 50px;
  /* background: red; */
  position: absolute;
  top: 50%;
  right: 6%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  padding: 0;
  color: #fff;
  text-align: center;
  background: #fff;
  border: 0;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.15s ease;
}
/* Landing-page---- */

/* brands section */

.brand-card {
  width: 150px; /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
  perspective: 1000px;
  position: relative;
  cursor: pointer;
  transition: transform 0.5s ease; /* Add transition for smooth flip */
}

.brand-card div {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease; /* Add transition for smooth flip */
}

.front {
  background-color: #ccc; /* Front side background color */
  color: #fff; /* Front side text color */
}

.back {
  background-color: #333; /* Back side background color */
  color: #fff; /* Back side text color */
  transform: rotateY(180deg);
}

.flipped .front {
  transform: rotateY(-180deg); /* Adjust rotation */
}

.flipped .back {
  transform: rotateY(0); /* Adjust rotation */
}

.back img {
  width: 100%;
  object-fit: contain;
  max-height: 100%;
}

.brand-wall {
  width: 450px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .brand-wall {
    width: 300px;
    flex-wrap: wrap;
  }
  .brand-card {
    width: 100px; /* Adjust width as needed */
    height: 100px; /* Adjust height as needed */
  }
}
@media (max-width: 992px) {
  .brand-wall {
    width: 270px;
    flex-wrap: wrap;
  }
  .brand-card {
    width: 90px; /* Adjust width as needed */
    height: 90px; /* Adjust height as needed */
  }
}

/* brands section end */

.btn-terms {
  text-decoration: none;
  color: white;
}

.terms-breadcrumb-container {
  padding-top: 70px;
}

.terms-breadcrumb {
  padding: 50px 0px;
  background: #6545ca;
  color: white;
  border-radius: 100% 0% 25% 25% / 0% 60% 16% 16%;
}
